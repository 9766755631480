import React from "react";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import Layout from "../../components/layout";
import TopHeader from "../../components/top_header";
import Footer from "../../components/footer";
import AnotherQuiz from "../../components/another_quiz";

import HeaderLogoImage from "../../images/common/header_logo@2x.png";
import ShinbuyaThumbnailImage from "../../images/common/thumbnail_shibuya.png";
import CalendarThumbnailImage from "../../images/common/thumbnail_calender.png";
import TennisThumbnailImage from "../../images/common/thumbnail_tennis.png";

import "../../styles/answers.scss";

export default function Answers() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Layout>
      <div className="answers">
        <TopHeader headerLogoImage={HeaderLogoImage} />

        <div id="check_answer_title_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div className="title">{t("mmind.answers.check_answer")}</div>
              </div>
            </div>
          </div>
        </div>

        <div id="check_answer_column">
          <div className="content_full_box">
            <div className="content_box">
              <div className="inner">
                <div className="answers_box">
                  <div className="answer">
                    <div className="counter">
                      <div className="counter_element">1</div>
                    </div>
                    <div className="text">
                      <div className="question_title">
                        {t("mmind.answers.q2_title")}
                      </div>
                      <div
                        className={`question_description dynamic_line_height ${
                          currentLanguage === "en" ? "line_height_narrow" : ""
                        }`}
                      >
                        <Trans i18nKey="mmind.answers.q2_description" />
                      </div>
                      <div className="question_answer">
                        {t("mmind.answers.q_answer_1")}
                        <span className="strong">
                          {t("mmind.answers.q2_answer")}
                        </span>
                        {t("mmind.answers.q_answer_3")}
                      </div>
                    </div>
                    <div className="image">
                      <img
                        alt=""
                        className="img-responsive center-block"
                        src={ShinbuyaThumbnailImage}
                      />
                    </div>
                  </div>
                  <div className="answer">
                    <div className="counter">
                      <div className="counter_element">2</div>
                    </div>
                    <div className="text">
                      <div className="question_title">
                        {t("mmind.answers.q3_title")}
                      </div>
                      <div
                        className={`question_description dynamic_line_height ${
                          currentLanguage === "en" ? "line_height_narrow" : ""
                        }`}
                      >
                        <Trans i18nKey="mmind.answers.q3_description" />
                      </div>
                      <div className="question_answer">
                        {t("mmind.answers.q_answer_1")}
                        <span className="strong">
                          {t("mmind.answers.q3_answer")}
                        </span>
                        {t("mmind.answers.q_answer_3")}
                      </div>
                    </div>
                    <div className="image">
                      <img
                        alt=""
                        className="img-responsive center-block"
                        src={CalendarThumbnailImage}
                      />
                    </div>
                  </div>
                  <div className="answer">
                    <div className="counter">
                      <div className="counter_element">3</div>
                    </div>
                    <div className="text">
                      <div className="question_title">
                        {t("mmind.answers.q4_title")}
                      </div>
                      <div
                        className={`question_description dynamic_line_height ${
                          currentLanguage === "en" ? "line_height_narrow" : ""
                        }`}
                      >
                        <Trans i18nKey="mmind.answers.q4_description" />
                      </div>
                      <div className="question_answer">
                        {t("mmind.answers.q_answer_1")}
                        <span className="strong">
                          {t("mmind.answers.q4_answer")}
                        </span>
                        {t("mmind.answers.q_answer_3")}
                      </div>
                    </div>
                    <div className="image">
                      <img
                        alt=""
                        className="img-responsive center-block"
                        src={TennisThumbnailImage}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {currentLanguage === "ja" ? <AnotherQuiz /> : ""}
        <Footer />
      </div>
    </Layout>
  );
}
